import React, { FC, useState } from 'react';
import styled from 'styles/styled-components';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { connect } from 'react-redux';
import { selectCategories } from '../selectors';
import { ProductCategory } from 'types/schema';
import { Dispatch } from 'redux';
import { setApplyFilter } from '../actions';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const CategoriesContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;

  ${media.md`
    flex-wrap: wrap;
    
    `}
`;

const CategoryItem = styled.div`
  padding: 6px 12px;
  border-radius: 20px;
  background: #ffffff;

  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  cursor: pointer;
  ${(props) =>
    props.isSelected
      ? 'border: 1px solid #43a047; color: #43A047; font-weight: 500;'
      : 'border: 1px solid #f1f1f1; color: #343538; font-weight: 400;'}

  &:hover {
    border-color: #43a047;
  }
`;
export interface IStateProps {
  categories: ProductCategory[];
}

interface DispatchProps {
  onFilterProduct: (categoryIds?: number[]) => void;
}

type Props = IStateProps & DispatchProps;

const Categories: FC<Props> = (props) => {
  const [selectedCategory, setSelectedCategory] = useState<number[]>();

  const { categories, onFilterProduct } = props;
  
  return (
    <CategoriesContainer>
      <CategoryItem
        isSelected={!selectedCategory || selectedCategory?.length === 0}
        key='all'
        onClick={() => {
          setSelectedCategory(undefined);
          onFilterProduct();
        }}
      >
        <FormattedMessage {...messages.all} defaultMessage="Tất cả" />
      </CategoryItem>
      {categories.map((category) => (
        <CategoryItem
          isSelected={selectedCategory?.includes(category.id)}
          key={category.name}
          onClick={() => {
            if (selectedCategory?.includes(category.id)) {
              const newCategoryIds = selectedCategory.filter((id) => id !== category.id);
              setSelectedCategory(newCategoryIds);
              onFilterProduct(newCategoryIds);
            } else {
              const newCategoryIds = [...(selectedCategory || []), category.id];
              setSelectedCategory(newCategoryIds);
              onFilterProduct(newCategoryIds);
            }
          }}
        >
          {category.name}
        </CategoryItem>
      ))}
    </CategoriesContainer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  categories: selectCategories(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onFilterProduct: (categoryIds?: number[]) =>
      dispatch(
        setApplyFilter({
          categoryIds: categoryIds,
        }),
      ),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Categories);
