/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.BrandDetailPage';

export default defineMessages({
  product: {
    id: `${scope}.product`,
    defaultMessage: 'sản phẩm',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'sản phẩm',
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: '...Xem thêm',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Thu gọn',
  },
  similarBrands: {
    id: `${scope}.similarBrands`,
    defaultMessage: 'Thương hiệu cùng danh mục',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'Tất cả',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'Xem thêm',
  },
  brandFrom: {
    id: `${scope}.brandFrom`,
    defaultMessage: 'Thương hiệu từ',
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: 'Thương hiệu',
  }
});
