import { all, call, fork, put, select, take } from 'redux-saga/effects';

import { ProductSortField, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { addFavorite, getBrand, getBrands, getProducts, removeFavorite, setProductNote } from 'utils/apollo';
import { brandDetail, similarBrands, brandProducts, brandDetailFavorite, brandDetailSetProductNote } from './actions';
import { createMatchSelector, push } from 'connected-react-router';
import ActionTypes from './constants';
import { selectBrandInfo, selectCategoryIds } from './selectors';
import { generateUrlBrandDetail } from 'utils/generateBrandDetailUrl';
import { loadingResources } from './reducer';
import { message } from 'antd';
import translations from 'translations';
import { updateNoteToCard } from 'containers/MainLayout/actions';
import utilMessages from 'utils/messages';
import { selectLang } from 'containers/MainLayout/selectors';
import { LANGUAGES } from 'utils/constants';

function* getBrandProducts({ brandId, categoryIds, brandName, brandLogo, page = 0, isMainBrand = false }: { brandId?: number; categoryIds?: number[], brandName?: string, brandLogo?: string, page?: number, isMainBrand?: boolean }) {
  const brandInfo = yield select(selectBrandInfo());
  let currentBrandId = brandInfo.id;

  if (brandId) {
    currentBrandId = brandId;
  }

  if (!page) {
    yield put(brandProducts.request(
      {
        [currentBrandId]: {
          id: currentBrandId,
          name: brandName || brandInfo.name,
          logo: brandLogo || brandInfo.logo,
          data: loadingResources,
          page: page,
        }
      }));

  }

  try {
    const params: QueryProductSearchArgs = {
      sort: [{ field: ProductSortField.Name, order: SortDirection.Asc }],
      filter: {
        brandId: Number(currentBrandId),
        categoryIds: isMainBrand ? categoryIds || [] : [],
      },
      pagination: { page: page || 0, size: 10 },
    };
    const response = yield call(getProducts, params);
    if (!response.errors) {
      yield put(brandProducts.success({
        [currentBrandId]: {
          id: currentBrandId,
          name: brandName || brandInfo.name,
          logo: brandLogo || brandInfo.logo,
          data: response.data,
          page: page,
          totalResults: response.totalResults
        }
      }));
    }
  } catch (error) {
    yield put(brandProducts.failure(error as Error));
  }
}

function* getGroupBrands(groupName: string, brandId: string) {
  const response = yield call(getBrands, {
    groupName: groupName,
    pagination: { page: 0, size: 1000 },
  });

  if (!response.errors) {
    const brands = response?.data;
    yield all(
      brands.map((item) => {
        if (brandId === item.id) return;
        return call(getBrandProducts, {
          brandId: item.id,
          brandName: item.name,
          brandLogo: item.imageUrl,
        });
      }),
    );
  }
}

function* filterFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_FILTER);
    yield call(getBrandProducts, {
      categoryIds: payload.categoryIds,
      isMainBrand: true,
    });
  }
}

function* viewMoreFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.VIEW_MORE);
    let categoryIds = []
    if (payload.isMainBrand) {
      categoryIds = yield select(selectCategoryIds());
    }
    yield call(getBrandProducts, {
      brandId: payload.brandId,
      page: payload.page,
      categoryIds: categoryIds,
      isMainBrand: payload.isMainBrand,
    });
  }
}


function* getSimilarBrands(categories: number[], brandId: string) {
  const response = yield call(getBrands, {
    categoryIds: categories,
    pagination: { page: 0, size: 1000 },
  });

  if (!response.errors) {
    const brands = response?.data?.filter((brand) => brand.id !== brandId);
    yield put(similarBrands.success(brands));
  } else {
    yield put(similarBrands.failure(response.errors));
  }
}

const replaceLinksWithAnchorTags = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
}
function* initBrandDetails() {
  // const localLang = yield select(selectLang());
  const localLang = localStorage.getItem('lang') || LANGUAGES.Vi;
  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate : LANGUAGES.Vi;
  yield put(brandDetail.request());

  const route = yield select(createMatchSelector('/thuong-hieu/:slug'));
  const slugs = route.params.slug;
  const slugsArray = slugs.split('-');
  const id = slugsArray[slugsArray.length - 1].slice(1);


  const response = yield call(getBrand, { brandId: Number(id) });

  if (!response.errors) {
    window.history.replaceState('', '', `${generateUrlBrandDetail(response.id, response.name, localLang)}`);
    yield put(
      brandDetail.success({
        brandInfo: {
          id: response.id,
          logo: response.imageUrl,
          name: response.name,
          description: localLang === 'vi' ? replaceLinksWithAnchorTags(response.description_vi) : replaceLinksWithAnchorTags(response.description),
          groupName: response.groupName,
        },
        localLang: localLang,
        alternateLang: alternateLang,
        categories: response.productCategories,
        brandSection: {
          [response.id]: { id: response.id, name: response.name, logo: response.imageUrl, data: loadingResources }
        }
      }),
    );

    yield all([
      call(getBrandProducts, {
        brandId: response.id,
      }),
      response.groupName && call(getGroupBrands, response.groupName, response.id),
      call(
        getSimilarBrands,
        response.productCategories.map((category) => category.id),
        id,
      ),
    ]);
  } else {
    yield put(brandDetail.failure(response.errors));
    yield put(push('/not-found'));
  }
}

function* brandDetailFavoriteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_FAVORITE);
    yield put(brandDetailFavorite.request());

    const {
      product: { isFavorite, id },
      index,
      section,
    } = payload;

    const response = yield call(isFavorite ? removeFavorite : addFavorite, { id: id });
    if (response.errors) {
      yield put(brandDetailFavorite.failure({ index: index, section: section }));
    } else {
      yield put(brandDetailFavorite.success({ index: index, section: section, isFavorite: !isFavorite }));
    }
  }
}

function* brandDetailSetProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_PRODUCT_NOTE);
    yield put(brandDetailSetProductNote.request());

    const { id, note, index, section } = payload;
    const response = yield call(setProductNote, { input: { productId: id, note: note } });
    if (response.errors) {
      yield put(brandDetailSetProductNote.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    } else {
      yield put(brandDetailSetProductNote.success({ note: note, index: index, section: section }));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    }
  }
}

export default function* saga() {
  yield fork(initBrandDetails);
  yield fork(filterFlow);
  yield fork(viewMoreFlow);
  yield fork(brandDetailFavoriteFlow);
  yield fork(brandDetailSetProductNoteFlow);
}
